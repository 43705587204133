import config from 'config';

/* eslint-disable no-useless-escape */
export function decodePhpHtmlString(s: string) {
  const map = {
    '&amp;': '&',
    '&#038;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#8217;': "'",
    '&#8216;': "'",
    '&#8211;': '–',
    '&#8212;': '—',
    '&#8230;': '…',
    '&#8221;': '"',
    '&acirc;': 'â',
    '&Acirc;': 'Â',
    '&icirc;': 'î',
    '&Icirc;': 'Î',
    '&nbsp;': ' ',
  };
  const storeUrl = config.zento.theme.storeData.storeUrl + '/m2/media/';
  const replacedContent = s.replace(/{{media url="([^"]+)"}}/g, (_, url) => {
    return url;
  });
  const parser = new DOMParser();
  const doc = parser.parseFromString(replacedContent, 'text/html');
  const images = doc.querySelectorAll('img');

  images.forEach((i) => {
    const parserImage = new DOMParser();
    const docImage = parserImage.parseFromString(i.outerHTML, 'text/html');
    const src = docImage.querySelector('img').getAttribute('src');

    if (src && !src.startsWith('http://') && !src.startsWith('https://')) {
      const correctUrl = storeUrl + src;

      i.setAttribute('src', correctUrl);
    } else {
      // Added more use cases
      // No src attribute found in the input HTML string
    }
  });

  const modifiedContent = new XMLSerializer().serializeToString(doc);

  return modifiedContent.replace(/\&[\w\d\#]{2,5}\;/g, (m) => {
    return map[m];
  });
}

export function extractImgTags(html: string): string[] {
  const imgTagRegex = /<img[^>]*>/g;
  const imgTags: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = imgTagRegex.exec(html))) {
    imgTags.push(match[0]);
  }

  return imgTags;
}

export function extractImgAttributes(imgTags: string[]): Array<{ [key: string]: string }> {
  const attrRegex = /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g;
  const imgAttrs: Array<{ [key: string]: string }> = [];

  imgTags.forEach((imgTag) => {
    const attrs: { [key: string]: string } = {};
    let match: RegExpExecArray | null;

    while ((match = attrRegex.exec(imgTag))) {
      attrs[match[1]] = match[2];
    }

    imgAttrs.push(attrs);
  });

  return imgAttrs;
}

export function extractContent(s: string) {
  const span = document.createElement('span');
  span.innerHTML = s;

  return span.textContent || span.innerText;
}
